/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Abstract"), "\n", React.createElement(_components.p, null, "In online games, a significant number of female gamers experience gender discrimination and respond to it in various ways.\nHowever, in a male-centered game community, female gamers' gender discrimination response strategies have structural limitations.\nThis study aims to find out the problems that occur when female gamers respond to gender discrimination in online games and find solutions to them.\nTo clarify the problem, We conducted interviews with 20 female gamers to identify the problem.\nMany interviewers complained that they lacked feedback on in-game reports and that their identity was sacrificed in the process of responding to gender discrimination.\nTo find a solution to these problems, seven game users and two game planners were recruited to hold a joint design workshop.\nThe results derived from the workshop were a reporting system that increased gender sensitivity and a voice modulation function that concealed gender.\nThis study emphasizes the importance of the value of inclusion for various users when designing in-game reporting systems."), "\n", React.createElement(_components.h2, null, "My Contribution"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Explored the literature of the joint design workshop"), "\n", React.createElement(_components.li, null, "Participated in designing the joint design workshop for the study."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
